import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "../shared/index.css";
import Root from "../modernbagelry/Root";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
]);

const appRoot = document.getElementById("app-root");

if (appRoot) {
  ReactDOM.createRoot(appRoot).render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>,
  );
}
