import { useCallback, useMemo, useRef, useState } from "react";
import QRCode from "qrcode-svg";
import { createConsumer } from "@rails/actioncable";
import Navbar from "../shared/Navbar";
import Button from "../shared/Button";
import Tag from "../shared/Tag";
import src from "./images/bagels.webp";
import Alert from "../shared/Alert";
import PromiseResolver from "../shared/PromiseResolver";

export default function Root() {
  const [purchasing, setPurchasing] = useState(false);
  const [content, setContent] = useState<string>();
  const [alertMessage, setAlertMessage] = useState<string>();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const closeDialog = useCallback(() => {
    dialogRef.current?.close("cancel");
  }, []);

  const onClick = useCallback(() => {
    setPurchasing(true);

    const virtualAccountPromise = new PromiseResolver<string>();
    const subscriptionPromise = new PromiseResolver<void>();

    const consumer = createConsumer();
    const cleanup = () => {
      subscription.unsubscribe();
      consumer.disconnect();
    };

    const onClose = () => {
      if (dialogRef.current?.returnValue === "cancel") {
        subscriptionPromise.reject();
        cleanup();
      }
    };
    const subscription = consumer.subscriptions.create(
      { channel: "PaymentChannel" },
      {
        received(response: Record<string, string>) {
          if (response.virtual_account) {
            virtualAccountPromise.resolve(response.virtual_account);
          } else {
            setAlertMessage(
              `Thank you for your purchase, we have received payment ${response.end_to_end_id}.`,
            );
            subscriptionPromise.resolve();
            cleanup();
          }
        },
      },
    );

    const showModal = async () => {
      dialogRef.current?.addEventListener("close", onClose);
      dialogRef.current?.showModal();
      try {
        await subscriptionPromise.promise;
        dialogRef.current?.close("success");
      } catch (e) {
        if (dialogRef.current?.open) {
          throw e;
        }
      } finally {
        dialogRef.current?.removeEventListener("close", onClose);
      }
    };

    const handlePayment = async () => {
      const virtualAccount = await virtualAccountPromise.promise;

      const code = Tag.toString([
        Tag.fromString("00", "01"),
        Tag.fromString("01", "12"),
        Tag.fromTagArray("26", [
          Tag.fromString("00", "app.modernwallet"),
          Tag.fromString("01", "071000301"),
          Tag.fromString("02", virtualAccount),
        ]),
        Tag.fromString("52", "5462"),
        Tag.fromString("53", "840"),
        Tag.fromString("54", "100.00"),
        Tag.fromString("58", "US"),
        Tag.fromString("59", "Modern Bagelry"),
        Tag.fromString("60", "San Francisco"),
        Tag.fromTagArray("62", [Tag.fromString("09", "AE")]),
      ]);

      setContent(code);

      await showModal();
    };
    handlePayment()
      .catch((error) => {
        console.error(`handlePayment() failed [${error}]`);
      })
      .finally(() => {
        setPurchasing(false);
      });
  }, []);

  const svg = useMemo(() => {
    if (content == null) {
      return "";
    }

    const qrcode = new QRCode({
      content,
      padding: 0,
      background: "transparent",
      join: true,
      ecl: "H",
    });
    return qrcode.svg();
  }, [content]);

  return (
    <>
      <Navbar />
      <dialog
        ref={dialogRef}
        className="w-full bg-transparent backdrop:bg-black/50 tablet:max-w-md"
      >
        <div className="pointer-events-auto relative flex w-full flex-col rounded-lg border border-black/20 bg-white bg-clip-padding outline-0">
          <div className="flex shrink-0 items-center justify-between border-b border-b-zinc-200 p-4">
            <span className="mb-0 text-xl">Scan QR Code</span>
            <button
              onClick={closeDialog}
              className="my-2 ml-auto mr-2 box-content h-4 w-4 rounded-md border-0 bg-transparent bg-close bg-auto bg-center bg-no-repeat text-black opacity-50"
              type="button"
              aria-label="Close"
            />
          </div>
          <div
            className="relative mx-auto flex-auto p-4"
            dangerouslySetInnerHTML={{ __html: svg }}
          />
          <div className="flex shrink-0 flex-wrap items-center justify-end border-t border-t-zinc-200 p-3">
            <Button onClick={closeDialog}>Close</Button>
          </div>
        </div>
      </dialog>
      <div className="mx-auto mt-3 w-full px-3 tablet:max-w-md">
        {alertMessage != null && <Alert message={alertMessage} />}
        <div className="relative flex min-w-0 flex-col break-words rounded-md border border-black/20 bg-white bg-clip-border text-black dark:border-white/20 dark:bg-neutral-800 dark:text-zinc-200">
          <img
            className="aspect-square w-full rounded-t-[calc(0.375rem-1px)]"
            alt="A dozen bagels"
            src={src}
          />
          <div className="flex-auto p-4">
            <h5 className="mb-2 text-xl">3 Dozen Bagels</h5>
            <h6 className="-mt-1 mb-2 text-neutral-800 opacity-75 dark:text-zinc-200">
              $100.00 USD
            </h6>
            <p className="mb-3">
              New York style bagels baked the way bagels were supposed to be
              made, with the highest quality all natural ingredients and lots of
              love. Modern Bagelry Bagels are proofed, boiled and bathed in cold
              water and then baked on wood boards in a revolving tray oven.
            </p>
            <Button disabled={purchasing} onClick={onClick}>
              {purchasing ? "Purchasing…" : "Purchase"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
