export default class PromiseResolver<T> {
  #promise: Promise<T>;

  #resolve: (value: T | PromiseLike<T>) => void;

  #reject: (reason?: unknown) => void;

  constructor() {
    this.#resolve = () => {};
    this.#reject = () => {};
    this.#promise = new Promise((resolve, reject) => {
      this.#resolve = resolve;
      this.#reject = reject;
    });
  }

  get promise() {
    return this.#promise;
  }

  resolve(value: T | PromiseLike<T>) {
    this.#resolve(value);
  }

  reject(reason?: unknown) {
    this.#reject(reason);
  }
}
